import SVG from 'react-inlinesvg';
import { Citation } from '../components/Citation';
import { Paper } from '../data_model';
import { paperIds } from '../paperIDs';
import { parsePaper } from '../parser';
import './Home.css';


const Home = () => {

    const papers = paperIds.map((paperId) => {
        let data = require('../data/papers/' + paperId + '.json');
        const paper = parsePaper(data);
        paper.id = paperId;
        return paper;
    });

    return <div className="Home">
        <h1>ExplainPrompt</h1>
        <p>ExplainPrompt provides detailed walkthroughs of the large language model prompts from recent research papers.</p>
        <div id="container">
            { papers.map((paper: Paper, index: number) => {
                return <div className="card">
                    <a href={ "papers/" + paper.id }>
                        <figure>
                        <SVG src={ 'images/minimaps/' + paper.id + '.svg' } width="110" height="200" title={ paper.title } />
                        </figure>
                        <h2>{ paper.title }</h2>
                    </a>
                    <Citation paper={ paper } />
                </div>;
            })}
        </div>
        <footer>Created by <a target="_blank" href="http://twitter.com/narphorium">Shawn Simister</a></footer>
    </div>;
  };

  export default Home;