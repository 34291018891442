import { ContentBlock } from 'ai-construction-set';
import styled from "styled-components";
import theme from 'styled-theming';


/* Tool Response */

const toolResponseSelectedTextColor = theme('mode', {
    light: '#222', 
    dark: '#bcdefe'
});


/* Descriptions */

const descriptionTextColor = theme('mode',  {
    light: '#222', 
    dark: '#ffde98',
});

const descriptionBgColor = theme('mode', {
    light: 'rgb(253 235 184)', 
    dark: 'rgb(73 69 61)',
});

const descriptionBorderColor = theme('mode', {
    light: 'rgb(237, 211, 137)', 
    dark: 'rgb(109 102 81)',
});

const toolDescriptionTextColor = theme('mode', {
    light: '#222', 
    dark: '#bcdefe',
});

const toolDescriptioneBgColor = theme('mode', {
    light: '#d8edff', 
    dark: 'rgb(60 108 194 / 24%)',
});

const toolDescriptionBorderColor = theme('mode', {
    light: '#a0c1e3',
    dark: '#4a5f79',
});

export const DescriptionBlock = styled(ContentBlock)`
    color: ${descriptionTextColor};
    background-color: ${descriptionBgColor};
    border-color: ${descriptionBorderColor};

    & a {
        color: ${descriptionTextColor};
    }

    &.tool-description {
        color: ${toolDescriptionTextColor};
        background-color: ${toolDescriptioneBgColor};
        border-color: ${toolDescriptionBorderColor};

        .aics-content-span,
        .aics-content-section > span > label {
            color: ${toolResponseSelectedTextColor} !important;
        }

        a {
            color: ${toolResponseSelectedTextColor};
        }
    }
`;