import '@vscode/codicons/dist/codicon.css';
import 'devicon/devicon.min.css';
import process from "process";
import './App.css';

import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";


import { Paper } from './data_model';
import Home from "./pages/Home";
import PaperView from "./pages/Paper";
import { paperIds } from './paperIDs';
import { parsePaper } from './parser';

const development: boolean = !process.env.NODE_ENV || process.env.NODE_ENV === 'development';

function isDev(): boolean
{
    return development;
}

export function paperLoader({ params}: { params: any}) {
  let paper: Paper | null = null;
  let paperId = params.paperId;
  paperId = paperId.replace(/\.html$/, '');
  if (isDev() || paperIds.indexOf(paperId) > -1) {
    let data = require('./data/papers/' + paperId + '.json');
    paper = parsePaper(data);
    paper.id = paperId;
  }
  return paper;
}

const App = () => {

  const router = createBrowserRouter([
    {
      path: "/",
      element: <Home />,
    }, {
      path: "/papers/:paperId",
      element: <PaperView />,
      loader: paperLoader,
    },
  ]);

  return (
    <div className={"App"}>
      <RouterProvider router={router} />
    </div>
  );
}

export default App;

