import { Content, Stream } from 'ai-construction-set';



export class Walkthrough {
    constructor(public steps: Content[]) {}
}

export class Paper {

    id: string = '';
    arxivId: string = '';
    githubId: string = '';
    trajectory: Stream = new Stream();
    walkthrough: Walkthrough = new Walkthrough([]);

    constructor(public title: string, public authors: string[], public year: number) {}
}