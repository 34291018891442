import { StepContext, StepDispatchContext } from 'explainprompt';
import React, { Dispatch, SetStateAction, useContext } from 'react';
import { GlobalHotKeys } from "react-hotkeys";
import { styled } from 'styled-components';
import theme from 'styled-theming';


type StepControlsProps = {
    className?: string;
};

export const StepControlsComponent = ({className}: StepControlsProps) => {

    const [btn1, setBtn1] = React.useState('');
    const [btn2, setBtn2] = React.useState('');
    const [btn3, setBtn3] = React.useState('');
    const [btn4, setBtn4] = React.useState('');

    const state = useContext(StepContext);
    const dispatch = useContext(StepDispatchContext);

    const gotoStart = React.useCallback(() => {
        dispatch?.({type: 'start'});
        pulseButton(btn1, setBtn1);
    }, [state]);

    const gotoEnd = React.useCallback(() => {
        dispatch?.({type: 'end'});
        pulseButton(btn4, setBtn4);
    }, [state]);

    const previousStep = React.useCallback(() => {
        dispatch?.({type: 'previous'});
        pulseButton(btn2, setBtn2);
    }, [state]);

    const nextStep = React.useCallback(() => {
        dispatch?.({type: 'next'});
        pulseButton(btn3, setBtn3);
    }, [state]);

    const keyMap = {
        PREVIOUS_STEP: ["p", "up", "pageup", "left", "backspace"],
        NEXT_STEP: ["n", "enter", "down", "pagedown", "right", "space"],
        START: ["home", "a"],
        END: ["end", "b"]
      };

    const hotkeyHandlers = {
        PREVIOUS_STEP: previousStep,
        NEXT_STEP: nextStep,
        START: gotoStart,
        END: gotoEnd
    };

    const getClassNames = () => {
        const classNames = ['controls'];
        if (className) {
            classNames.push(className);
        }
        return classNames.join(' ');
    }

    const pulseButton = (btn: string, setBtn: Dispatch<SetStateAction<string>>) => {
        setBtn('pulse1');
        setTimeout(() => {
            setBtn('');
        }, 400);
    };

    return <div className={getClassNames()}>
        <GlobalHotKeys keyMap={keyMap} handlers={hotkeyHandlers} allowChanges={true} />
        <button className={"button-start " + btn1} title="Return to start [home]" onClick={gotoStart}><span className="material-icons material-icons-outlined">first_page</span></button>
        <button className={"button-end " + btn2} title="Previous step [←]" onClick={previousStep}><span className="material-icons material-icons-outlined">chevron_left</span></button>
        <span className="position">{ state?.step } of { state?.numSteps }</span>
        <button className={"button-start " + btn3} title="Next step [→]" onClick={nextStep}><span className="material-icons material-icons-outlined">chevron_right</span></button>
        <button className={"button-end " + btn4} title="Jump to end [end]" onClick={gotoEnd}><span className="material-icons material-icons-outlined">last_page</span></button>
    </div>;
};

const buttonBgColor = theme('mode', {
    light: '#e3e3e3',
    dark: 'rgba(255 255 255 / 7%)',
});

const buttonTextColor = theme('mode', {
    light: '#6e7071',
    dark: '#bbbec9',
});

const buttonHoverBgColor = theme('mode', {
    light: '#ccc',
    dark: 'rgba(255 255 255 / 20%)',
});

const buttonPulseBgColor = theme('mode', {
    light: '#a6a6ab',
    dark: 'rgba(255 255 255 / 30%)',
});


export const StepControls = styled(StepControlsComponent)`
    text-align: center;
    margin-bottom: 16px;

    .position {
        vertical-align: top;
        line-height: 26px;
        margin: 0 8px;
        font-family: Arial, Helvetica, sans-serif;
        font-size: 11pt;
    }
      
    button {
        border: 0;
        background-color: ${buttonBgColor};
        border-radius: 8px;
        color: ${buttonTextColor};
        margin: 0 1px;
        padding: 0 4px;
        height: 26px;

        span {
            font-size: 24px;
            margin: 1px 0;
        }
    }

    button.button-start {
        border-radius: 6px 0 0 6px;
    }

    button.button-end {
        border-radius: 0 6px 6px 0;
    }
      
    button:hover {
        background-color: ${buttonHoverBgColor};
    }
      
    button:focus {
        outline: 0;
    }

    button.pulse1 {
        animation-name: pulse1;
        animation-duration: 0.2s;
        animation-iteration-count: 1;
        animation-direction: alternate-reverse;
        animation-timing-function: ease;
    }
      
    @keyframes pulse1 {
        0% {}
        50% {
          background-color: ${buttonPulseBgColor};
        }
        100% {}
    }
`;