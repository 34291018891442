import { parseContent, parsePromptChain } from 'explainprompt';
import { Paper, Walkthrough } from "./data_model";

export const parseWalkthrough = (json: any): Walkthrough => {
    return new Walkthrough(json.steps.map((parseContent)));
}

export const parsePaper = (json: any): Paper => {
    const paper = new Paper(json['title'], json['authors'], json['year']);
        if (json['arxiv']) {
            paper.arxivId = json['arxiv'];
        }
        if (json['github']) {
            paper.githubId = json['github'];
        }
        if (json['trajectory']) {
            paper.trajectory = parsePromptChain(json);
        }
        if (json['walkthrough']) {
            paper.walkthrough = parseWalkthrough(json['walkthrough']);
        }
        return paper;
};