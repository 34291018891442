import { BlockFactory, BlockFactoryContext, Content } from 'ai-construction-set';
import { BlockStream, PaperBlockFactory, SelectedElementContext, StepProvider, StepState } from 'explainprompt';
import { useCallback, useEffect, useState } from "react";
import { useLoaderData } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import { useDarkMode } from 'usehooks-ts';
import { Citation } from '../components/Citation';
import { DescriptionBlock } from '../components/ContentBlock';
import { StepControls } from '../components/StepControls';
import { Paper } from '../data_model';
import './Paper.css';


const PaperView = () => {
    const { isDarkMode } = useDarkMode()
    const [theme, setTheme] = useState(isDarkMode ? 'dark' : 'light');
    const [factory, setFactory] = useState<BlockFactory>(new PaperBlockFactory());
    const [step, setStep] = useState(1);
    const [element, setElement] = useState<HTMLElement>();
    const paper = useLoaderData() as Paper;
    const walkthrough = paper.walkthrough;
    const num_steps = walkthrough.steps.length;

    const [description, setDescription] = useState<Content>(walkthrough.steps[0]);

    const [flag, setFlag] = useState(false);

    useEffect(() => {
        document.title = 'ExplainPrompt - ' + paper.title;
        // if (location.hash.indexOf('#') == 0) {
        //     gotoStep(parseInt(location.hash.slice(1)));
        // }
    }, []);

    useEffect(() => {
        setTheme(isDarkMode ? 'dark' : 'light');
    }, [isDarkMode]);

    useEffect(() => {
        history.replaceState(null, '', '#' + step);
        setDescription(walkthrough.steps[step - 1])
    }, [step]);


    const getDescriptionClasses = useCallback(() => {
        let description_classes = ['selected'];
        const paper_block_factory = factory as PaperBlockFactory;
        const selected_block = paper_block_factory.getSelectedChildren(paper.trajectory, step);
        if (selected_block.length > 0 && (
            selected_block[0].classNames.has('prompt-tool-named-content') || 
            selected_block[0].classNames.has('tool-response') ||
            selected_block[0].classNames.has('tool-span') ||
            selected_block[0].classNames.has('action') ||
            selected_block[0].classNames.has('tool'))) {
                description_classes.push('tool-description');
        }
        return description_classes.join(' ');
    }, [step]);

    const toggle = () => {
        setFlag(!flag);
    }

    const stepChange = useCallback((state: StepState) => {
        setDescription(walkthrough.steps[state?.step - 1]);
        setStep(state?.step ?? 1);
    }, [description]);

    return <div className="Paper">
        <ThemeProvider theme={{'mode': theme}}>
        <StepProvider step={step} numSteps={num_steps} onChange={stepChange}>
        <BlockFactoryContext.Provider value={{ factory, setFactory }}>
        <SelectedElementContext.Provider value={{ element, setElement }}>
            <div className="aside">
                <div className="breadcrumbs">
                    <a href="/">ExplainPrompt</a>
                    <span className="material-icons material-symbols-outlined">chevron_right</span>
                    Papers
                </div>
                <h1>{ paper.title }</h1>
                <Citation paper={paper} />
                <StepControls />
                <DescriptionBlock className={getDescriptionClasses()} content={description} selected={true} key={description.uuid} />
                <footer>Created by <a target="_blank" href="http://twitter.com/narphorium">Shawn Simister</a></footer>
            </div>
            <div className="trajectory">
            <BlockStream stream={paper.trajectory} page={1} setPage={()=>{}} key={paper.trajectory.uuid}/>
            </div>
        </SelectedElementContext.Provider>
        </BlockFactoryContext.Provider>
        </StepProvider>
        </ThemeProvider>
    </div>;
  };

  export default PaperView;