
import { useState } from "react";
import styled from "styled-components";
import theme from 'styled-theming';
import { Paper } from '../data_model';
import { CollapsibleText } from "./CollapsibleText";

export type CitationProps = {
    className?: string;
    paper: Paper;
};


const CitationComponent = ({className, paper}: CitationProps) => {

    const [collapseAuthors, setCollapseAuthors] = useState(true);

    const getLinks = (paper: Paper) => {
        const links = [];
        if (paper.arxivId !== "") {
            links.push({
                icon: '',
                url: 'http://arxiv.org/abs/' + paper.arxivId,
                title: 'arXiv:' + paper.arxivId
            });
        }
        if (paper.githubId !== "") {
            links.push({
                icon: 'codicon codicon-github-inverted',
                url: 'https://github.com/' + paper.githubId,
                title: ''
            });
        }
        return links;
    }

    const getClassNames = () => {
        const classNames = ['citation'];
        if (className) {
            classNames.push(className);
        }
        return classNames.join(' ');
    }

    return <div className={getClassNames()}>
        <span className="authors">
            <CollapsibleText text={paper.authors} limit={12} collapsed={collapseAuthors} toggleCollapsed={()=>{setCollapseAuthors(!collapseAuthors)}}/>
        </span>
        { getLinks(paper).map((link, index) => {
            return <a key={index} target="_blank" href={link.url}><i className={link.icon}></i>{link.title}</a>;
        })}
        (<span className="year">{ paper.year }</span>)
    </div>;
};


const citationTextColor = theme('mode', {
    light: '#666',
    dark: '#bbb',
});

export const Citation = styled(CitationComponent)`
    color: ${citationTextColor};
    font-size: 10pt;
    margin-bottom: 24px;

    & a {
        color: ${citationTextColor};
        margin: 0 4px;
    }

    & .codicon {
        font-size: 12px;
    }

    & .authors a {
        text-decoration: underline;
        cursor: pointer;
        white-space: nowrap;
    }

    & .authors .author {
        white-space: nowrap;
    }
`;