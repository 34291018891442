import { Dispatch, SetStateAction, useCallback, useEffect, useState } from "react";

type CollapsibleTextProps = {
    text: string[];
    limit: number;
    collapsed?: boolean | Dispatch<SetStateAction<boolean>>;
    className?: string;
    toggleCollapsed: () => void;
}

export const CollapsibleText = ({text: items, limit, collapsed, className, toggleCollapsed}: CollapsibleTextProps) => {
    const [shownItems, setShownItems] = useState<string[]>(items);

    useEffect(() => {
        if (items.length > limit) {
            setShownItems(items.slice(0, limit - 2));
        }
    }, []);

    useEffect(() => {
        if (collapsed && items.length > limit) {
            setShownItems(items.slice(0, limit - 2));
        } else {
            setShownItems(items);
        }
    }, [shownItems,collapsed]);

    const handleClick = useCallback(() => {
        toggleCollapsed();
    }, [collapsed,shownItems]);

    if (items.length > limit) {
        return <>
            { shownItems.map((item, i) => {
                return <><span className="author">{ item }</span>{ i < items.length - 1 ? ', ' : '' }</>;
            })};
            <a onClick={handleClick}>({ collapsed ? `+${items.length - shownItems.length} more` : 'show less' })</a>
        </>;
    } else {
        return <>
        { items.map((item, i) => {
            return <><span className="author">{ item }</span>{ i < items.length - 1 ? ', ' : '' }</>;
        })}
        </>;
    }
};